import React from 'react'
import '../../Style/RiyoLogistics/RiyoLogisticsTestimonials.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import image from '../../Asset/Home/riyotestimosliderimgae.jpg'
import { Bounce } from 'react-awesome-reveal';

function RiyoLogisticsTestimonials({ logisticAllData }) {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
    />
    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
            />
            <div className="RiyoLogisticsTestimonials">
                <div className='RiyoLogisticsTestimonials-heading'>
                    <h1><Bounce>Testimonials</Bounce></h1>
                    <div className="RiyoLogisticsTestimonials-heading-line"></div>
                </div>
                <div className="RiyoLogisticsTestimonials-slider-Main">
                <Slider {...settings}>
                    <div>
                        <div className="RiyoLogisticsTestimonials-slider-box">
                            <div className='RiyoLogisticsTestimonials-slider-box-paragraph'>
                                <p>{logisticAllData?.testimonials.slider4.paragraph}</p>
                                <p>
                                    <b>{logisticAllData?.testimonials.slider4.name}<br />
                                        {logisticAllData?.testimonials.slider4.director} <br />
                                        {logisticAllData?.testimonials.slider4.logistics}
                                    </b>
                                </p>
                            </div>
                            <div className='RiyoLogisticsTestimonials-slider-box-Image'>
                                <div className='riyo-slider-image-background-box'></div>
                                <div className='riyo-slider-image-background-box2'></div>
                                <img src={image} height="100%" width="100%" alt="" />
                            </div>
                        </div>
                    </div>
                        <div>
                            <div className="RiyoLogisticsTestimonials-slider-box">
                                <div className='RiyoLogisticsTestimonials-slider-box-paragraph'>
                                    <p>{logisticAllData?.testimonials.slider1.paragraph}</p>
                                    <p>
                                        <b>{logisticAllData?.testimonials.slider1.name}<br />
                                            {logisticAllData?.testimonials.slider1.director} <br />
                                            {logisticAllData?.testimonials.slider1.logistics}
                                        </b>
                                    </p>
                                </div>
                                <div className='RiyoLogisticsTestimonials-slider-box-Image'>
                                    <div className='riyo-slider-image-background-box'></div>
                                    <div className='riyo-slider-image-background-box2'></div>
                                    <img src={image} height="100%" width="100%" alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="RiyoLogisticsTestimonials-slider-box">
                                <div className='RiyoLogisticsTestimonials-slider-box-paragraph'>
                                    <p>{logisticAllData?.testimonials.slider2.paragraph}</p>
                                    <p>
                                        <b>{logisticAllData?.testimonials.slider2.name}<br />
                                            {logisticAllData?.testimonials.slider2.director} <br />
                                            {logisticAllData?.testimonials.slider2.logistics}
                                        </b>
                                    </p>
                                </div>
                                <div className='RiyoLogisticsTestimonials-slider-box-Image'>
                                    <div className='riyo-slider-image-background-box'></div>
                                    <div className='riyo-slider-image-background-box2'></div>
                                    <img src={image} height="100%" width="100%" alt="" />
                                </div>
                            </div> 
                        </div>
                        <div>
                            <div className="RiyoLogisticsTestimonials-slider-box-main-box3">
                            <div className="RiyoLogisticsTestimonials-slider-box">
                                <div className='RiyoLogisticsTestimonials-slider-box-paragraph'>
                                    <p>{logisticAllData?.testimonials.slider3.paragraph}</p>
                                    <p>
                                        <b>{logisticAllData?.testimonials.slider3.name}<br />
                                            {logisticAllData?.testimonials.slider3.director} <br />
                                            {logisticAllData?.testimonials.slider3.logistics}
                                        </b>
                                    </p>
                                </div>
                                <div className='RiyoLogisticsTestimonials-slider-box-Image'>
                                    <div className='riyo-slider-image-background-box'></div>
                                    <div className='riyo-slider-image-background-box2'></div>
                                    <img src={image} height="100%" width="100%" alt="" />
                                </div>
                            </div>
                            </div>
                        </div>
                    </Slider>
                   
                </div>
            </div>
        </>
    )
}

export default RiyoLogisticsTestimonials
