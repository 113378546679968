import React from 'react'
import '../../Style/RiyoServices/RiyoLogSection.css'
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import { Link } from 'react-router-dom';

function RiyoLogSection() {

    const dispatch = useDispatch()
    const handale = (value) => {
        dispatch(setCurrentPage(value))
    }

    return (
        <div className='riyo-log-section'>
            <div className='riyolog-div'>
                <div className='riyo-services-box1'>
                    <div className='riyo-services-imgbox'>
                        <div className='riyo-services-imgbox2'>
                        </div>
                    </div>
                </div>
                <div className='riyo-services-box2'>
                    <div className='riyo-services-para'>
                        <div className='circle-text'>
                            <h1 className='riyo-log-text'>RIYO Logistics</h1>
                        </div>
                        <div className='riyo-text-para2'>
                            <p><span className='span-at'>At</span><span className='span-riyo'> RIYO LOGISTICS,</span> we understand that each journey is unique, and each cargo holds its own set of challenges. That's why our approach is flexible, adaptive, and tailored to meet the specific needs of our clients. From real-time tracking and monitoring to proactive problem-solving, we are with our partners every step of the way, ensuring a hassle-free experience from start to finish. </p>
                            <Link to="/RiyoLogistics">
                                <button onClick={()=>handale("riyoLogisticData")}>Know more</button>
                            </Link>
                        </div>

                    </div>

                </div>


            </div>

        </div>
    )
}

export default RiyoLogSection