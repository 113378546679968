import React, { useEffect, useState } from 'react'
import '../../Style/RiyoLogistics/RiyoPackage.css'
import { Slide } from 'react-awesome-reveal';
import { useSelector } from 'react-redux';
import Logo from '../../Asset/RiyoLogistics/packagelogo.svg'

function RiyoPackage({ logisticAllData }) {
  const [hidecard, setHidecard] = useState(true)
  const [hidecards, setHidecards] = useState(true)
  const currentPage = useSelector((state) => state.Data.currentPage);
  console.log(currentPage)
  useEffect(() => {
    if (currentPage === "RiyoBusinessData" ) {
      setHidecard(false)
    } else {
      setHidecard(true)
    }
    if (currentPage === "RiyoShurakhshaData" ) {
      setHidecards(false)
      setHidecard(false)
    } else {
      setHidecards(true)
    }
    if (currentPage === "RiyoParivaarData" ) {
      setHidecards(false)
      setHidecard(false)
    }
  })
  return (
    <>
      <div className="RiyoPackage">
        <div className="RiyoPackage-heading">
          <div>
            <Slide>
              <h1>{logisticAllData?.Parivahan.Heading}</h1>
              <h2>{logisticAllData?.Parivahan.subHeading}</h2>
            </Slide>
          </div>
        </div>
        <div className="RiyoPackage-all-details">
          {
            hidecard ?<>
            
            <div className="RiyoPackage-all-details-card">
                <div className="RiyoPackage-details-card-heading">
                  <h3>{logisticAllData?.Parivahan.card1.topHeading}</h3>
                </div>
                {
                  logisticAllData?.Parivahan.card1.lines.map((res) => (
                    <div className='RiyoPackage-details-card-details'>
                      <img src={Logo} alt="" />
                      <p >{res}</p>
                    </div>
                  ))
                }
                <div className="Riyocardbottomline2">
                  <h3>{logisticAllData?.Parivahan.card1.bottomHeading}</h3>
                </div>
              </div>
            </>:<></>
          }
          {
            hidecards?<>
             <div className="RiyoPackage-all-details-card1">
            <div className="RiyoPackage-details-card-heading">
              <h3>{logisticAllData?.Parivahan.card2.topHeading}</h3>
            </div>
            {
              logisticAllData?.Parivahan.card2.lines.map((res) => (
                <div className='RiyoPackage-details-card-details'>
                  <img src={Logo} alt="" />
                  <p>{res}</p>
                </div>
              ))
            }
            <div className="Riyocardbottomline1">
              <h3> {logisticAllData?.Parivahan.card2.bottomHeading}</h3>
            </div>
          </div>
            </>:<>
            <h1 className='comming'>Comming Soon !</h1>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default RiyoPackage
