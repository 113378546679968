import React from 'react'
import '../../Style/RiyoServices/RiyoServicesApplication.css'
import image from '../../image/Group 2914 (1).svg'
import RIYOBusinessQR from "../../Asset/RiyoBusiness/RiyoBusiness QR.png"
import RIYOLogisticsQR from "../../Asset/RiyoLogistics/riyo logistics QR.png"
import RIYOShurakhshaQR from "../../Asset/RiyoSurakhsha/riyo suraksha QR.png"
import RIYOParivaarQR from "../../Asset/RiyoParivaar/riyo parivaar QR.png"

function RiyoServicesApplication() {
  return (

    <>
      <div className='riyo-servicesapplicaton-div'>
        <div className='riyo-servicesapplicaton-div2'>
          <p className='riyo-heading-p'>RIYO Application is now available for download on Play Store.</p>
          <div className='riyo-servicesapplicaton-img'>
                                   {/*  */}
              <div className='m_responsive-help'>

            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={RIYOParivaarQR} height="100%" width="100%" alt='' />
                  <p>RIYO Parivaar</p>
                </div>
                <div class="flip-card-back">
                  <p><b>RIYO Parivar</b></p>
                  <p>For Lorry Driver</p>
                 

                </div>
              </div>
            </div>

            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={RIYOLogisticsQR} height="100%" width="100%" alt='' />
                  <p>RIYO Logistic</p>
                </div>
                <div class="flip-card-back">
                  <p><b>RIYO Logistic</b></p>
                  <p>For Industry And Transport Company</p>
                  
                </div>
              </div>
            </div>

            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={RIYOBusinessQR} height="100%" width="100%" alt='' />
                  <p>RIYO Business</p>
                </div>
                <div class="flip-card-back">
                  <p><b>RIYO Business</b></p>
                  <p>For Lorry Broker</p>
                  
                </div>
              </div>
            </div>
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={RIYOShurakhshaQR} height="100%" width="100%" alt='' />
                  <p>RIYO Surakhsha</p>
                </div>
                <div class="flip-card-back">
                  <p><b>RIYO Surakhsha</b></p>
                  <p>For Lorry Owner</p>
                </div>
              </div>
            </div>

            </div>
            <div className='riyo-servicesapplicaton-logo'>
              <img src={image} height="96% " width="100%" alt='' />
            </div>
          </div>
        </div>
      </div>
      
    </>
 )
 }
 
export default RiyoServicesApplication