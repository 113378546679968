import React, { useState } from 'react';
import '../../Style/Layout/Header.css';
import Logo from '../../Asset/Logo/RiyoWeb.png';
import { Link } from 'react-router-dom';
import { TfiMenu } from "react-icons/tfi";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    const handleclick = () =>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  return (
    <>
      <div className="HeaderNaBar">
        <div className="toggle" >
            <TfiMenu size={30} onClick={toggleMenu} style={{color:'white'}} />
            <img src={Logo} alt="" height="28px" />
        </div>
        <ul className={isOpen ? 'open' : ''}>
          <li onClick={handleclick}><Link to='/'>Home</Link></li>
          <div className='HeaderNaBar-line'></div>
          <li onClick={handleclick}><Link to='/AboutUsRiyoBusiness'>About Us</Link></li>
          <div className='HeaderNaBar-line'></div>
          <li onClick={handleclick}><Link to='/RiyoServices'>RIYO Services</Link></li>
          <div className='HeaderNaBar-line'></div>
          <li ><img src={Logo} alt="RiyoWeb Logo" height='50.1rem' /></li>
          <div className='HeaderNaBar-line'></div>
          <li>Gallery</li>
          <div className='HeaderNaBar-line'></div>
          <li>Blog</li>
          <div className='HeaderNaBar-line'></div>
          <li onClick={handleclick}><Link to='/ContactUs'>Contact Us</Link></li>
        </ul>
      </div>
    </>
  );
}

export default Header;
