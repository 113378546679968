import React from 'react'
import '../../Style/RiyoServices/HowToRegister.css'

function HowToRegister() {
  return (
    <>
     
    </>
  )
}

export default HowToRegister