import React, { useEffect, useState } from 'react'
import '../../Style/RiyoLogistics/RiyoSignup.css'
import { Slide } from 'react-awesome-reveal'
import Logo from '../../Asset/RiyoLogistics/Vector (4).svg'
import { useSelector } from 'react-redux';
import RIYOBusinessQR from "../../Asset/RiyoBusiness/RiyoBusiness QR.png"
import RIYOLogisticsQR from "../../Asset/RiyoLogistics/riyo logistics QR.png"
import RIYOShurakhshaQR from "../../Asset/RiyoSurakhsha/riyo suraksha QR.png"
import RIYOParivaarQR from "../../Asset/RiyoParivaar/riyo parivaar QR.png"


function RiyoSignUp({ logisticAllData }) {
  const currentPage = useSelector((state) => state.Data.currentPage);
  const [image, setImage] = useState()

  
  useEffect(() => {
    if (currentPage === "RiyoBusinessData") {
      setImage(RIYOBusinessQR);
    } else {
      setImage(RIYOLogisticsQR);
    }
    if (currentPage === "RiyoShurakhshaData") {
      setImage(RIYOShurakhshaQR);
    } else {
      setImage(RIYOLogisticsQR);
    }
    if (currentPage === "RiyoParivaarData") {
      setImage(RIYOShurakhshaQR);
    } else {
      setImage(RIYOParivaarQR);
    }
  }, [currentPage]);


  return (
    <>
      <div className="RiyoSignUp">
        <div className="RiyoSignUp-heading">
          <h1>How To SignUp?</h1>
          <div className="RiyoSignUp-heading-line"></div>
        </div>
        <div className="RiyoSignUp-details-main">
          <div className="RiyoSignUp-details">
            <Slide direction='right' duration={1000}>
              <div className='RiyoSignUp-screenShot'>
                <img src='https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?t=st=1715441655~exp=1715445255~hmac=e68feaed46fd174c3793e76eccf94e4da21f92e75448a72cb2f3256dba6f6958&w=996' height="100%" width="100%" alt="" />
                <div className='mobile-notch'>
                  <center> <img src="https://img.freepik.com/free-photo/speakers-with-extremely-high-volume-blue-background_23-2149625677.jpg?t=st=1714551444~exp=1714555044~hmac=5eecd16ca2ee9eb1e87f55f672d3b6fecf6919304e3cbf5542047db74f6bb932&w=740" height="10px" alt="" /></center>
                </div>
              </div>
            </Slide>
            <Slide direction='right' duration={2000}>
              <div className='RiyoSignUp-screenShot'>
                <img src='https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?t=st=1715441655~exp=1715445255~hmac=e68feaed46fd174c3793e76eccf94e4da21f92e75448a72cb2f3256dba6f6958&w=996' height="100%" width="100%" alt="" />
                <div className='mobile-notch'>
                  <center> <img src="https://img.freepik.com/free-photo/speakers-with-extremely-high-volume-blue-background_23-2149625677.jpg?t=st=1714551444~exp=1714555044~hmac=5eecd16ca2ee9eb1e87f55f672d3b6fecf6919304e3cbf5542047db74f6bb932&w=740" height="10px" alt="" /></center>
                </div>
              </div>
            </Slide>
            <Slide direction='right' duration={3000}>
              <div className='RiyoSignUp-screenShot'>
                <img src='https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?t=st=1715441655~exp=1715445255~hmac=e68feaed46fd174c3793e76eccf94e4da21f92e75448a72cb2f3256dba6f6958&w=996' height="100%" width="100%" alt="" />
                <div className='mobile-notch'>
                  <center> <img src="https://img.freepik.com/free-photo/speakers-with-extremely-high-volume-blue-background_23-2149625677.jpg?t=st=1714551444~exp=1714555044~hmac=5eecd16ca2ee9eb1e87f55f672d3b6fecf6919304e3cbf5542047db74f6bb932&w=740" height="10px" alt="" /></center>
                </div>
              </div>
            </Slide>
            <Slide direction='right' duration={4000} >
              <div className='RiyoSignUp-screenShot'>
                <img src='https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?t=st=1715441655~exp=1715445255~hmac=e68feaed46fd174c3793e76eccf94e4da21f92e75448a72cb2f3256dba6f6958&w=996' height="100%" width="100%" alt="" />
                <div className='mobile-notch'>
                  <center> <img src="https://img.freepik.com/free-photo/speakers-with-extremely-high-volume-blue-background_23-2149625677.jpg?t=st=1714551444~exp=1714555044~hmac=5eecd16ca2ee9eb1e87f55f672d3b6fecf6919304e3cbf5542047db74f6bb932&w=740" height="10px" alt="" /></center>
                </div>
              </div>
            </Slide>
          </div>
        </div>
        <div className="RiyosignUpJionDetail">
          <div className="RiyosignUpJion-paragraph">
            <div className='RiyosignUpJionDetail2'>
              <Slide>
                <div>
                  <h1>Join Now </h1>
                </div>
                <div>
                  <img src={Logo} style={{ marginTop: "12px" }} alt="" />
                </div>
              </Slide>
            </div>
            <p>{logisticAllData?.joinNowParagraph}</p>
          </div>
          <div className="RiyosignUpJion-QR-Code">
            <div className='RiyosignUpJion-QR-Code-div'>
              <h2>Scan QR</h2>
              <div className='RiyosignUpJion-QR-Code-box'>
                <img src={image} alt="QR Code"/>
              </div>
            </div>
            <div className='Mobile-box'>
              <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" height="50px" alt="" />
              <a href={logisticAllData?.link} >Click to Download</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RiyoSignUp
