import React from 'react'
import '../../Style/AboutUsRiyoBusiness/Aboutbusiness.css'



function AboutBusiness() {
  return (
    <>
    <div className='AboutBusiness-div1'>
        <div className='AboutBusiness-heading'>
            <h1>About Us</h1>
            <p>Home {'>'} <span className='span-color'>AboutUs</span></p>
        </div>
    </div>
    <div className='AboutBusiness-div2'>
        <div className='box1-1'>
            <div className='box1-11'>
            <p className='about-para'>About RIYO Business</p>
            <h5>WHAT MADE RIYO COME TO LIFE?</h5>
            <div className='riyobusiness-line'>
            </div>
            <p className='para1-1'>At RIYO, our mission is to revolutionize logistics through seamless technological integration. Leveraging the Riyo app, we connect industry segments, simplify operations, and optimize efficiency.Eco-system to transportation drivers and logistics-dependent industries, our platform fosters cohesion and streamlines operations. We unite stakeholders, distribute workloads, and enhance overall effectiveness. Dedicated to industry advancement, we cultivate collaboration and innovation. Our goal is to facilitate seamless interaction, driving improvement in logistics management.</p>
            <div className='btn-1-1'>View Our Service
            <div className='circle'>
                <p>&#x2197;</p>
               
            
            </div>
            
            </div>
            </div>
    
            
        </div>
        <div className='box2-2'>
            <div className='box2-22'>
               <div className='img-box11'>
                <div className='image-box11'>

                </div>
                <div className='image-box22'>
                </div>

               </div>

               <div className='img-box-22'>
                <div className='image-box33'>

                </div>
                <div className='image-box44'>

                </div>

               </div>

            </div>

             
        </div>


    </div>
    </>
  )
}

export default AboutBusiness