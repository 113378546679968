import React, { useState } from 'react';
import Logo from "../../image/Group 3426.png";
import '../../Style/Layout/Footer.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare } from 'react-icons/fa';
function Footer() {
    const dispatch = useDispatch();
    const [isMainLinksOpen, setIsMainLinksOpen] = useState(false);
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
    const [isSupportLinksOpen, setIsSupportLinksOpen] = useState(false);
    const toggleMainLinks = () => {
        setIsMainLinksOpen(!isMainLinksOpen);
        setIsQuickLinksOpen(false);
        setIsSupportLinksOpen(false);
    };
    const toggleQuickLinks = () => {
        setIsQuickLinksOpen(!isQuickLinksOpen);
        setIsMainLinksOpen(false);
        setIsSupportLinksOpen(false);
    };
    const toggleSupportLinks = () => {
        setIsSupportLinksOpen(!isSupportLinksOpen);
        setIsMainLinksOpen(false);
        setIsQuickLinksOpen(false);
    };
    const handleChange = (value) => {
        dispatch(setCurrentPage(value));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const redirectToWhatsApp = () => {
        window.location.href = `https://wa.me/+916289688570`; 
    };

    return (
        <div className="Footer">
            <div className="Footer-detials">
                <div className='Footer-detials-box'>
                    <img src={Logo} height="80px" alt='' />
                    <p>At RIYO, we're your one-stop solution for all your logistics needs. From seamless transportation to efficient warehousing and supply chain management, we've got you covered. Our dedicated team and cutting-edge technology ensure your goods reach their destination on time, every time. Experience hassle-free logistics with RIYO today</p>
                </div>
                <div className="Footer-line"></div>
                <div className="Footer-detials-box2">
                    <h1 onClick={toggleMainLinks}>Main Links</h1>
                    <div className={`Footer-detials-box2-some ${isMainLinksOpen ? 'open' : ''}`}>
                        <p onClick={scrollTop}><Link to='/'>Home</Link></p>
                        <p onClick={scrollTop}><Link to='/AboutUsRiyoBusiness'>About Us</Link></p>
                        <p onClick={scrollTop}><Link to="/RiyoServices">RIYO Services</Link></p>
                        <p>Gallery</p>
                    </div>
                </div>
                <div className="Footer-line"></div>
                <div className="Footer-detials-box2">
                    <h1 onClick={toggleQuickLinks}>Quick Links</h1>
                    <div className={`Footer-detials-box2-some ${isQuickLinksOpen ? 'open' : ''}`}>
                        <p>Blog</p>
                        <p onClick={() => handleChange("riyoLogisticData")}><Link to='/RiyoLogistics'>RIYO Logistics</Link></p>
                        <p onClick={() => handleChange("RiyoBusinessData")}><Link to='/RiyoLogistics'>RIYO Business</Link></p>
                        <p onClick={() => handleChange("RiyoShurakhshaData")}><Link to='/RiyoLogistics'>RIYO Surakhsha</Link></p>
                        <p onClick={() => handleChange("RiyoParivaarData")}><Link to='/RiyoLogistics'>RIYO Parivaar</Link></p>
                    </div>
                </div>
                <div className="Footer-line"></div>
                <div className="Footer-detials-box2">
                    <h1 onClick={toggleSupportLinks}>Support</h1>
                    <div className={`Footer-detials-box2-some ${isSupportLinksOpen ? 'open' : ''}`}>
                        <p onClick={scrollTop}><Link to="/ContactUs">Contact us</Link></p>
                        <p onClick={scrollTop}><Link to="/TermsAndConditions">Terms and conditions</Link></p>
                        <p onClick={scrollTop}><Link to="/PrivcyPolicy">Privacy Policy</Link></p>
                    </div>
                </div>
            </div>
            <div className="Footer-logo">
                <div className="Footer-logo-image">
                    <a href="https://www.facebook.com/share/8vVBgoU3yA1YYoZX/?mibextid=qi2Omg"> <FaFacebookSquare size={40} /></a>
                    <a href="https://www.instagram.com/riyoindia_?igsh=MXQ4MHc0eGV4OWt6NA=="><FaInstagramSquare size={40} /></a>
                    <a href="#" onClick={redirectToWhatsApp} ><FaWhatsappSquare size={40} /></a>
                </div>
            </div>
            <div className="footer-all-grid">
                <div className="footer-boxes">
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                    <div className="footer-boxes-line"></div>
                </div>
                <div className="footer-boxes-Y-line">
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                    <div className="footer-boxes-Y-line-1"></div>
                </div>
            </div>
        </div>
    );
}
export default Footer;






