import React from 'react'
import { Bounce } from 'react-awesome-reveal'
import '../../Style/RiyoLogistics/RiyoOurCollaborations.css'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';

function RiyoOurCollaborations({ logisticAllData }) {
    const [counterOn, setCounterOn] = useState(false)
    return (
        <>
            <div className="RiyoOurCollaborations">
                <div className='RiyoOurCollaborations-box item top'>
                    <h1><Bounce>{logisticAllData?.collaborations[0].heading}</Bounce></h1>
                </div>
                <div className='RiyoOurCollaborations-box2 item'>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                        <h1>
                            <Bounce>{counterOn && <CountUp start={0} end={logisticAllData?.collaborations[1].heading2} duration={2} delay={0} />}
                            </Bounce>
                        </h1>
                    </ScrollTrigger>
                    <h3>{logisticAllData?.collaborations[1].detail}</h3>
                </div>
                <div className='RiyoOurCollaborations-box2 item' >
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                        <h1>
                            <Bounce>{counterOn && <CountUp start={0} end={logisticAllData?.collaborations[2].heading3} duration={2} delay={0} />}
                            </Bounce>
                        </h1>
                    </ScrollTrigger>
                    <h3>{logisticAllData?.collaborations[2].detail}</h3>
                </div>
                <div className='RiyoOurCollaborations-box2 item bottom'>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                        <h1>
                            <Bounce>{counterOn && <CountUp start={0} end={logisticAllData?.collaborations[3].heading4} duration={2} delay={0} />}
                            </Bounce>
                        </h1>
                    </ScrollTrigger>
                    <h3>{logisticAllData?.collaborations[3].detail}</h3>
                </div>


            </div>
        </>
    )
}

export default RiyoOurCollaborations
