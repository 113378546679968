import React from 'react'
import '../../Style/RiyoServices/RiyoServicesMobileApp.css'


function RiyoServicesMobileApp() {
  return (
    <>
    <div className='riyoservicesmobile-div'>
        <div className='riyoservicesmobil-content'>
            <p className='riyoservicesmobil-heading'>RIYO Mobile App</p>
            <div className='riyoservicesmobil-line'>
            </div>
            <p className='comprehensive1-para'>A comprehensive highway assistance solution offering top-notch facilities and services to elevate your highway journey. From dining options to immediate SOS Emergency support, everything you need is at your fingertips. Access the app instantly by scanning the QR code provided or download it directly from the App Store or Play Store.</p>
        </div>
    </div>
    </>
  )
}
export default RiyoServicesMobileApp