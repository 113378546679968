import '../../Style/Home/HomeAourImpact.css'
import { Bounce, Slide } from 'react-awesome-reveal'
// import { FaLongArrowAltRight } from 'react-icons/fa';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from 'react';

function HomeAouImpact() {
  const [counterOn, setCounterOn] = useState(false)

  return (
    <>
      <div className="HomeAouImpact">
        <div className="HomeAouImpact-bg">
          <div className="HomeAouImpact-detail">
            <div className='text_Homeaboimpact'>
              <div className='text-line_conatiner'>
                <h1><Bounce>Our Impact</Bounce></h1>
                <div className='line_of_homeabimpact'></div>
              </div>
              <p><Slide>Turning insights into action has driven our impact on clients and communities over the years.</Slide></p>
            </div>
          </div>

          <div className="HomeAouImpact-detail-2" >
            <div className='HomeAouImpact-detail-2-space'>

              <div className='circle_round'>

              </div>
              <div className='text-round'>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                  <h1>
                    <Bounce>{counterOn && <CountUp start={0} end={225} duration={2} delay={0} />}
                    </Bounce>
                  </h1>
                </ScrollTrigger>
              </div>

              <p>Industries</p>
            </div>
            <div className='HomeAouImpact-detail-2-space'>
              <div className='circle_round'>

              </div>
              <div className='text-round'>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                  <h1>
                    <Bounce>{counterOn && <CountUp start={0} end={1467} duration={2} delay={0} />}
                    </Bounce>
                  </h1>
                </ScrollTrigger>
              </div>



              <p>Transport Companies</p>
            </div>

            <div className='HomeAouImpact-detail-2-space'>

              <div className='circle_round'>

              </div>
              <div className='text-round'>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                  <h1>
                    <Bounce>{counterOn && <CountUp start={0} end={2289} duration={2} delay={0} />}
                    </Bounce>
                  </h1>
                </ScrollTrigger>
              </div>

              <p>Lorry Brokers</p>
            </div>


            <div className='HomeAouImpact-detail-2-space'>
              <div className='circle_round'>

              </div>
              <div className='text-round'>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                  <h1>
                    <Bounce>{counterOn && <CountUp start={0} end={612} duration={2} delay={0} />}
                    </Bounce>
                  </h1>
                </ScrollTrigger>
              </div>


              <p>Lorry Owners</p>
            </div>
            <div className='HomeAouImpact-detail-2-space'>

              <div className='circle_round'>

              </div>
              <div className='text-round'>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                  <h1>
                    <Bounce>{counterOn && <CountUp start={0} end={25120} duration={2} delay={0} />}
                    </Bounce>
                  </h1>
                </ScrollTrigger>
              </div>
              <p>Lorry Drivers</p>
            </div>


          </div>

        </div>
      </div>
    </>
  )
}

export default HomeAouImpact
