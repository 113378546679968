import React from 'react'
import '../../Style/Home/HomeCompanyCorner.css'
import Slider from 'react-slick';
import sliderimage1 from '../../Asset/Home/slider image 1.jpg'
import sliderimage2 from '../../Asset/Home/slider image 2.jpg'
import sliderimage3 from '../../Asset/Home/slider image 3.jpg'
import sliderimage4 from '../../Asset/Home/slider image 4.jpg'
import sliderimage5 from '../../Asset/Home/slider image 5.jpg'
import sliderimage6 from '../../Asset/Home/slider image 6.jpg'
import sliderimage8 from '../../Asset/Home/slider image 8.jpg'
import sliderimage9 from '../../Asset/Home/slider image 9.jpg'
import sliderimage11 from '../../Asset/Home/slider image11.jpg'
// import { Bounce, Zoom } from 'react-awesome-reveal'

function HomeCompanyCorner() {






    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <></>,
                    prevArrow: <></>,
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },

        ],
    };
    return (
        <>
            <div className='home-company-corner_container'>
                <div className='pattern_container'>
                    <div className='HomeCompanyCorner_pattern_1'>
                        <div className='HomeCompanyCorner_pattern_2'>

                        </div>
                    </div>
                </div>
                {/* show only mobile view */}
                <div className="slider-container">
                    <div className='card_text'>
                        <div className='card_text_1'>
                            <h1>Company Corner</h1>
                            <div className='card_text_1_line'></div>
                        </div>


                    </div>
                    {/* <div className='slider_center'> */}
                    <Slider {...settings}>
                        <div>
                            <div className='card_1'>
                                <div className='card_box'>
                                    <div className='bg-card'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage1} alt='' />
                                    </div>
                                </div>
                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Adhiraj Mishra <br/> <span>Chief Of Marketing</span></p>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='card_2'>
                                <div className='card_box2'>
                                    <div className='bg-card2'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage2} alt='' />
                                    </div>
                                </div>
                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Nivedita Tiwari <br/>  <span>Director</span></p>
                                       
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div>
                            <div className='card_1'>
                                <div className='card_box'>
                                    <div className='bg-card'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage3} alt='' />
                                    </div>
                                </div>
                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Sujit Mishra <br/>  <span>CEO</span></p>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='card_2'>
                                <div className='card_box2'>
                                    <div className='bg-card2'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage6} alt='' />
                                    </div>
                                </div>

                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Kunal Dey <br/> <span>Operation Head</span></p>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='card_2'>
                                <div className='card_box2'>
                                    <div className='bg-card2'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage8} alt='' />
                                    </div>
                                </div>

                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Ratul Dutta <br/> <span>Customer Relation Manager</span></p>
                                         
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='card_1'>
                                <div className='card_box'>
                                    <div className='bg-card'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage9} alt='' />
                                    </div>
                                </div>

                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Priya Majumder <br /> <span>Customer Relation Executive</span></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='card_1'>
                                <div className='card_box'>
                                    <div className='bg-card'> </div>
                                </div>
                                <div className='center_card'>
                                    <div className='bg-card'>
                                        <img src={sliderimage11} alt='' />
                                    </div>
                                </div>

                                <div className='text_card'>
                                    <div className='text_card_1'>
                                        <p>Satyabrata Saha <br /> <span>Customer Relation Manager</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* done */}


                    </Slider>
                    {/* </div> */}
                </div>






            </div>
        </>
    )
}

export default HomeCompanyCorner


