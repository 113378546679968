import React from 'react'
import AboutBusiness from '../components/AboutUsRiyoBusiness/AboutBusiness'
import MissionVision from '../components/AboutUsRiyoBusiness/MissionVision'
import Header from '../components/Layout/Header'
import RiyoServicesMobileApp from '../components/RiyoServices/RiyoServicesMobileApp'
import RiyoServicesApplication from '../components/RiyoServices/RiyoServicesApplication'
function AboutUsRiyoBusiness() {
  
  return (
    <>
      <Header />
      <AboutBusiness />
      <MissionVision />
      <RiyoServicesMobileApp />
      <RiyoServicesApplication />
    </>
  )
}

export default AboutUsRiyoBusiness