import React from 'react'
import '../../Style/RiyoServices/Download.css'
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import { IoMdCheckmark } from "react-icons/io";
import rig from "../../image/reg1.jpg"
import rig1 from "../../image/reg2.png"


function Download() {
  return (
    <>
     <div className='register-div'>
        <h4>How to Register</h4>
      </div>
      <div className='download-div'>
     
        <div className='download-div2'>
       

          <div className='download-wrap'>
            <div className='download-box1'>
              <img src={rig} alt='' />
            </div>
            <div className='download-box2'>
              <img src={rig1} alt='' />
            </div>
            <div className='download-box3'>
              <div className='div-for-logo'>
                <div className='like'>
                  <BiSolidLike className='like-dislike' />
                </div>
                <div className='like'>
                  <BiSolidDislike className='like-dislike' />
                </div>
              </div>
              <div className='div-for-sub'>
                <p>Suscribe</p>
              </div>
            </div>
          </div>


          <div className='download-img-wrap'>
            <div className='download-double-box'>
              <div className='download-img-border'>
                <IoMdCheckmark className='check-icon' />

              </div>
              <div className='download-img-border'>
                <IoMdCheckmark className='check-icon' />

              </div>
              <div className='download-img-border'>
                <IoMdCheckmark className='check-icon' />
              </div>
            </div>
          </div>
          <div className='download-text'>
            <div className='download-content'>
              <div className='download-circle'>
                <h1 >1</h1>
              </div>
              <h2 style={{margin:"0"}}>Download</h2>
              <p>Download the app, available on Play store App Store and the<br /> Website.</p>
            </div>
            <div className='download-content'>
              <div className='download-circle'>
                <h1 style={{margin:"0"}}>2</h1>
              </div>
              <h2 style={{margin:"0"}}>Fill up the details</h2>
              <p style={{margin:"0"}}>Fill up your Personal Information to Verify Your Identity to initiate the<br /> process.</p>

            </div>
            <div className='download-content'>
              <div className='download-circle circle3'>
                <h1 style={{margin:"0"}}>3</h1>


              </div>
              <h2 style={{margin:"0"}}>Subscribe</h2>
              <p style={{margin:"0"}}>Subscribe any package to get the best Service Across Indian highway.</p>

            </div>

          </div>
        </div>



      </div>





    </>
  )
}

export default Download