import '../../Style/Home/HomeOurPresence.css';
import { Bounce } from 'react-awesome-reveal';
import OurPresencemap from '../../Asset/Logo/Map.png'

function HomeOurPresence() {

  return (
    <>
      <div className="HomeOurPresence">
        <div className='HomeOurPresence-box'>
          <div className="HomeOurPresence-box-details">
            <h1><Bounce>Our Presence</Bounce></h1>
            <div className='HomeOurPresence-box_line'></div>
          </div>
          <p>We are thrilled to announce that we are on our way to serving the entire country! Our goal is to reach every corner of the nation, providing top-notch services to all our valued customers. While we continue to work towards this ambitious goal, we are excited to share that we have already extended our services to several new locations.</p>
          <p>We understand that waiting can be challenging, but we want you to know that we are working diligently to expand our reach. Our commitment to excellence and customer satisfaction drives us to move forward with our expansion plans at a rapid pace.
            In the meantime, we are pleased to inform you that our services are now available in the following locations:</p>
        </div>
        <div className="HomeOurPresence-box2">
       <img src={OurPresencemap} alt="" />
        </div>
      </div>
    </>
  );
}
export default HomeOurPresence;
