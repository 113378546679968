import React from "react";
import Header from "../components/Layout/Header";
import HeroPrivcyPlicy from "../components/PrivcyPolicy/HeroPrivcyPolicy"
import PrivcyPolicyContant from "../components/PrivcyPolicy/PrivcyPolicy";

const PrivcyPolicy = () => {
  return (
    <>
      <Header />
      <HeroPrivcyPlicy />
      <PrivcyPolicyContant />
    </>
  );
};

export default PrivcyPolicy;
