import React from 'react'
import '../../Style/RiyoServices/HeroHomeRiyoServices.css'

function HeroHomeRiyoServices() {
  return (
    <div className='riyoservices-div'>
        <h1>RIYO Services</h1>
    </div>
  )
}

export default HeroHomeRiyoServices