import React from 'react'
import '../../Style/Home/HomeGetTouch.css'
import { Bounce } from 'react-awesome-reveal'
import ImageContact from "../../Asset/RiyoLogistics/GetintouchLogo.svg"
import Vector1 from "../../Asset/Home/Vector (1).png"
import Vector2 from "../../Asset/Home/Vector (2).png"
import Vector3 from "../../Asset/Home/Vector (3).png"
function HomeGetTouch() {
  return (
    <>
      <div className="HomeGetTouch">
        <div className="HomeGetTouch-heading">
          <h1><Bounce>Get in Touch</Bounce></h1>
        </div>
        <div className="HomeGetTouch-details-all">

          <div className='box-icon-container'>
          <div className='box_icon'>
          <a href="tel:03335561099" style={{ textDecoration: 'none', display: 'flex',gap:'1rem', alignItems: 'center' }} >
            <div className='icon_container_round'>
              <img src={Vector1} alt='logo' />
            </div>
            <p>033 35561099</p>
          </a>
          </div>
          <div className='box_icon'>
            <a href="mailto:Hello@riyorentitoverindia.com" style={{ textDecoration: 'none', display: 'flex',gap:'1rem', alignItems: 'center' }}>
            <div className='icon_container_round'>
              <img src={Vector2} alt='logo' />
            </div>
            <p>Hello@riyorentitoverindia.com</p>
            </a>
          </div>
          <div className='box_icon'>
            <div className='icon_container_round2'>
              <img src={Vector3} alt='logo' />
            </div>
            <p>2nd Floor SK Tower, Behind Reliance Market, Niranjanpur, Mandi, Dehradun, UK, 248001, India</p>
          </div>
          </div>
          <div className='image-get'>
            <img src={ImageContact} alt='' />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeGetTouch
