import React from 'react'
import '../../Style/RiyoLogistics/riyoLogo.css'
import { Bounce } from 'react-awesome-reveal';
import logo1 from '../../Asset/RiyoLogistics/Vector.svg'
import logo2 from '../../Asset/RiyoLogistics/Vector2.svg'
import logo3 from '../../Asset/RiyoLogistics/Vector 3.svg'
import logo4 from '../../Asset/RiyoLogistics/Vector 4.svg'
import logo5 from '../../Asset/RiyoLogistics/Vector 5.svg'


function RiyoLogo({ logisticAllData }) {
  return (


    <>
      <div className="RiyoLogo">
        <div className="RiyoLogoHeading">
          <h1><Bounce>Other Benefits</Bounce></h1>
          <div className="RiyoHeadingline"></div>
        </div>
        <div className="RiyoLogo-all-details">
          <div className="RiyoLogo-box">
            <div className="RiyoLogo-box-logo">
              <img src={logo1} alt="" />
            </div>
            <p>{logisticAllData?.logos[0]}</p>
          </div>
          <div className="RiyoLogo-box">
            <div className="RiyoLogo-box-logo">
              <img src={logo2} alt="" />
            </div>
            <p>{logisticAllData?.logos[1]}</p>
          </div>
          <div className="RiyoLogo-box">
            <div className="RiyoLogo-box-logo">
              <img src={logo3} alt="" />
            </div>
            <p>{logisticAllData?.logos[2]}</p>
          </div>
          <div className="RiyoLogo-box">
            <div className="RiyoLogo-box-logo">
              <img src={logo4} alt="" />
            </div>
            <p>{logisticAllData?.logos[3]}</p>
          </div>
          <div className="RiyoLogo-box">
            <div className="RiyoLogo-box-logo">
              <img src={logo5} alt="" />
            </div>
            <p>{logisticAllData?.logos[4]}</p>
          </div>
        </div>


        {/* mobile show */}
        <div className="Mobile-responsive">
          <div className='mobile'>
            <div className="RiyoLogo-box">
              <div className="RiyoLogo-box-logo">
                <img src={logo1} alt="" />
              </div>
              <p>{logisticAllData?.logos[0]}</p>
            </div>
            <div className="RiyoLogo-box">
              <div className="RiyoLogo-box-logo">
                <img src={logo2} alt="" />
              </div>
              <p>{logisticAllData?.logos[1]}</p>
            </div>
            <div className="RiyoLogo-box">
              <div className="RiyoLogo-box-logo">
                <img src={logo3} alt="" />
              </div>
              <p>{logisticAllData?.logos[2]}</p>
            </div>
          </div>

          <div className='mobile2'>
            <div className="RiyoLogo-box">
              <div className="RiyoLogo-box-logo">
                <img src={logo4} alt="" />
              </div>
              <p>{logisticAllData?.logos[3]}</p>
            </div>
            <div className="RiyoLogo-box">
              <div className="RiyoLogo-box-logo">
                <img src={logo5} alt="" />
              </div>
              <p>{logisticAllData?.logos[4]}</p>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default RiyoLogo
