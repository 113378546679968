import React from 'react'
import '../../Style/Home/HomeSection2.css'
import anima from '../../Asset/Home/homesectionanimation.gif'


function HomeSection2() {
  return (
    <>
      <div className='section_seconds_container'>
        <div className='Images_conatiner'>
          <div className='image_wrapper'>
              <div className="img-div"></div>
          </div>
        </div>
        <div className='Text_conatiner  main-conatiner'>
          <div className='textitems'>
          <div className='top_wrapper'>
              <div className='Logo_container_wrappe'>
              <img src={anima} alt='logo' />
                <div className='l_w_p'>
                  <p className='p_logo_25'><span>25</span> Years</p>
                </div>
              </div>
              </div>
            <div className='textitems_text'>
              <p>Expertise you can count on</p>
              <div className='line'></div>
            </div>
          </div>
          <div className='text_conatiner'>
            <p>With 25 years of industry experience, RIYO stands as a stalwart in the logistics and transportation sector, dedicated to fostering meaningful impact and value for its clients. Through decades of navigating the intricacies of the industry, Riyo has honed its expertise to deliver innovative solutions that drive success and sustainability. Committed to staying at the forefront of industry advancements, Riyo leverages its deep understanding of logistics and transportation dynamics to anticipate market shifts and deliver proactive strategies. By prioritizing client needs and maintaining a relentless pursuit of excellence, Riyo has established itself as a trusted partner in optimizing supply chain operations and driving business growth. Whether it's streamlining processes, implementing cutting-edge technologies, or navigating regulatory landscapes, Riyo's seasoned professionals are equipped to provide tailored solutions that propel clients towards their goals, cementing its reputation as a leader in the field.</p>
          </div>
        </div>
        {/* pattern */}
        <div className='Design_pattern_cotainer'>
          <div className='pattern_1'>
            <div className='pattern_2'></div>
          </div>
        </div>
      </div>

    </>
  )
}

export default HomeSection2
