import React from 'react'
import '../../Style/RiyoServices/RiyoParivaar.css'
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import { Link } from 'react-router-dom';

function RiyoParivaar() {
    const dispatch = useDispatch()
    const handale = (value) => {
        dispatch(setCurrentPage(value))
    }
  return (
    <div className='riyo-parivar-section'>
        <div className='riyoparivar-div'>
            <div className='riyo-parivar-box2'>
                <div className='riyo-parivar-para'>
                    <div className='parivarcircle-text'>
                    <h1 className='riyo-parivar-text'>RIYO Parivaar</h1>
                    </div>
                    <div className='riyo-parivar-para2'>
                        <p><span className='span-at'>At </span><span className='span-riyo'>RIYO PARIVAAR,</span> We unlock a smoother journey for commercial truck drivers navigating the diverse landscape of national highways. RIYO Rent It Over India Pvt Ltd introduces the RIYO Family policy, aimed at revolutionizing driving conditions for truckers. With a focus on addressing language barriers, cultural differences, and essential needs, RIYO's innovative mobile application streamlines access to vital services right on the highway, empowering drivers with convenience at their fingertips. </p>
                        <Link to="/RiyoLogistics">
                                <button onClick={()=>handale("RiyoParivaarData")}>Know more</button>
                            </Link>
                    </div>
                </div>
            </div>
            <div className='riyo-parivar-box1'>
                        <div className='riyo-parivar-imgbox'>
                            <div className='riyo-parivar-imgbox1'>
                            </div>
                        </div>
                    </div>

        </div>

    </div>
  )
}

export default RiyoParivaar