import React from 'react'
import '../../Style/Term&Conditions/Term&conditionHeroSection.css'
import Logo from '../../image/Group 3426.png'
import grid from '../../image/grid.png'
import Truck from "../../image/truckimg.png"
import industry from "../../image/industry.png"

function HerohomeTermAndConditions() {
  return (
    <>
      <div className='terms-conditions'>
        <div className='terms-box'>
          <img src={grid} className='terms-grid' alt=''/>
          <img src={Logo} alt="" height='110%' className='search-logo' />
          <h1>Privacy & Policy</h1>
          {/* <div className='truck-container'> */}

          <div className='truck'>
          <img src={Truck} className='img22' alt='' />
            <img src={Truck} className='img22' alt=''/>
            <img src={industry} className='img33' alt=''/>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default HerohomeTermAndConditions