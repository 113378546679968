import RiyoHero from '../components/Riyologistics/RiyoHero'
import RiyoLogisticsSection2 from '../components/Riyologistics/RiyoLogisticsSection2'
import RiyoPackage from '../components/Riyologistics/RiyoPackage'
import RiyoLogo from '../components/Riyologistics/RiyoLogo'
import RiyoSignUp from '../components/Riyologistics/RiyoSignUp'
import RiyoOurCollaborations from '../components/Riyologistics/RiyoOurCollaborations'
import RiyoLogisticsTestimonials from '../components/Riyologistics/RiyoLogisticsTestimonials'
import RiyoLogisticsGetInTouch from '../components/Riyologistics/RiyoLogisticsGetInTouch'
import Jsondata from "../components/RiyoLogisticsAllData/datas.json";
import { useSelector } from 'react-redux';
import Header from '../components/Layout/Header'
function RiyoLogistics() {
  const currentPage = useSelector((state) => state.Data.currentPage);
  const pagesData = Jsondata[currentPage];
  console.log(pagesData)
  return (
    <>
           <Header/>
          <RiyoHero logisticAllData={pagesData} />
          <RiyoLogisticsSection2 logisticAllData={pagesData} />
          <RiyoPackage logisticAllData={pagesData} />
          <RiyoLogo logisticAllData={pagesData} />
          <RiyoSignUp logisticAllData={pagesData} />
          <RiyoOurCollaborations logisticAllData={pagesData} />
          <RiyoLogisticsTestimonials logisticAllData={pagesData} />
          <RiyoLogisticsGetInTouch logisticAllData={pagesData} />
    </>
  )
}
export default RiyoLogistics

