import React from 'react'
import '../../Style/RiyoLogistics/RiyoLogisticsSection2.css'

function RiyoLogisticsSection2({ logisticAllData }) {

  console.log('hgfhgchgcgdgfdgd', logisticAllData.image1)

  return (
    <>
      <div className="RiyoLogisticsSection2">

        <div className="RiyoLogisticsSection2-details">
          <div className="paragraphdiv">
            <p><div className="RiyoLogisticsSection2-details-circle"></div> <span className='RiyoLogisticsSection2-details-pagename'> <span style={{ color: "var(--clr-blue)" }}>At </span>{logisticAllData?.riyoPageName}</span>, {logisticAllData?.paragraph}</p>
            <p>{logisticAllData?.paragraph2}</p>
          </div>
        </div>
        <div className='RiyoLogisticsSection2-all-details'>
          <div className="RiyoLogisticsSection2-all-details-image-box">
            <div className='RiyoLogisticsSection2-all-details-image-box-image'>
              <img src={logisticAllData?.image1} alt="" />
            </div>
            <div className='RiyoLogisticsSection2-all-details-image-box-details1'>
              <div className="RiyoLogisticsSection2-all-details-image-box-details1-sircle"></div>
            </div>
          </div>
          <div className="RiyoLogisticsSection2-all-details-image-box-2">
            <div className='RiyoLogisticsSection2-all-details-image-box-details1'>
              <div className="RiyoLogisticsSection2-all-details-image-box-details1-sircle-2"></div>
            </div>
            <div className='RiyoLogisticsSection2-all-details-image-box-image'>
              <img src={logisticAllData?.image2} alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RiyoLogisticsSection2
