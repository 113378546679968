import React from 'react'
import '../../Style/RiyoLogistics/RiyoHero.css'
import { Bounce } from 'react-awesome-reveal'

function RiyoHero({ logisticAllData }) {
  return (
    <>

      <div className="RiyoLogisticsHero">
        <div className="opecity">
          <div className="RiyoLogisticsHero-detail" >
            <div  >
              <h1 style={{display:"flex"}}><Bounce> <span>{logisticAllData.heading} </span><span style={{ paddingLeft:"8px"}} className='RiyoLogisticsHeading'>{logisticAllData.heading2}</span></Bounce></h1>
              <p style={{ textAlign: "end", fontWeight:"500" }}>{logisticAllData.description}</p>
            </div>
            <div className="RiyoGrids">
              <div className="Riyo-y-line">
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
                <div className="y-line"></div>
              </div>
              <div className="Riyo-x-line">
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
                <div className="x-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RiyoHero
