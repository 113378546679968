import React from 'react'
import '../../Style/Home/HomeAboutUs.css'
import { Bounce } from 'react-awesome-reveal'
import homeAboutImage from '../../Asset/RIYOservices/RIYOservicesParivaar.jpg'
import image1 from '../../Asset/Home/RiyoHomeAboutimage1.jpg'
import image3 from '../../Asset/Home/homeaboutimage3.jpg'
import { Link } from 'react-router-dom'

function HomeAboutUs() {
    return (
        <>
            <div className="HomeAboutUs">
                <div className="HomeAboutUs-image">
                    <div className='img1'>
                        <div className='img-wrapper'>
                            {/* <img src={image1} alt=''/> */}
                        </div>
                        <div className='img-wrapper'>
                            <img src={homeAboutImage} alt='' />
                        </div>
                    </div>
                    <div className='img2'>
                        <img src={image3} alt='' />
                    </div>
                </div>
                <div className="HomeAboutUs-details">
                    <div className=''>
                        <Bounce> <h1>About Us</h1></Bounce>
                        <div className='HomeAboutUs-details_line'></div>
                    </div>
                    <p>At RIYO, our mission is to revolutionize the logistics ecosystem through seamless integration of cutting-edge technology. With the RIYO App, we aim not only to bridge the gap between all stakeholders in the industry but also to simplify their daily operations, making tasks more manageable and efficient. Operating across every facet of the sector, from commercial vehicle drivers to industries utilizing logistics services, we strive to bring together all key players onto a unified platform. Our objective is to empower them to streamline their workflows and enhance productivity. </p>
                    <Link to="/AboutUsRiyoBusiness"><button>Know More</button></Link>
                </div>
            </div>
        </>
    )
}

export default HomeAboutUs
