import React from 'react'
import '../../Style/Home/HomeOurservice.css'
import { Bounce } from 'react-awesome-reveal'
import Logo from "../../Asset/Logo/whitelogo.png"
import Icon1 from "../../Asset/Home/icon1.png"
import Icon2 from "../../Asset/Home/icon2.png"
import Icon3 from "../../Asset/Home/icon3.png"
import Icon4 from "../../Asset/Home/man.png"
import { Link } from 'react-router-dom'
function HomeOurservice() {
    return (
        <>
            <div className="HeroHomeOurservice">
                <img src={Logo} alt="" className='HeroHomeOurservice-bg' />

                <div className="HeroHomeOurservice-all-details">
                    <div className="OurServices-details">
                        <h1><Bounce>Our Services</Bounce></h1>
                        <div className='OurServices-line'></div>
                        <div className="OurServices-details-Logo">
                            <p>At RIYO, we take pride in our diverse range of services,
                                meticulously crafted to meet the unique requirements of each
                                client. Understanding the pivotal roles within the logistics
                                industry, we have developed a comprehensive suite of offerings
                                tailored to address the specific needs of various stakeholders.
                                From manufacturers to shippers to carriers, we cater to a broad
                                spectrum of clients, ensuring that every aspect of their
                                operations is seamlessly supported. We firmly believe that the
                                smooth operation of vehicles on the road hinges upon the
                                synchronized functioning of all components. With this ethos in
                                mind, we have curated specialized services designed to
                                optimize performance and foster collaboration across the
                                industry.</p>
                            <Link to="/RiyoServices"><button >Know More</button></Link>
                        </div>
                    </div>
                    <div className="HeroHomeOurservice-scrollbar">
                        <div className='logos_conatiner_logo'>
                            <div className='logos_items'>
                                <div className='line_middle'></div>
                                <div className='upper_logos'>
                                    <div className='logo_box_logo'>
                                        <img src={Icon1} alt='icon.png' />
                                    </div>
                                    <div className='logo_box_logo'>
                                        <img src={Icon3} alt='icon.png' />
                                    </div>
                                    <div className='logo_box_logo'>
                                        <img src={Icon2} alt='icon.png' />
                                    </div>
                                    <div className='logo_box_logo'>
                                        <img src={Icon4} alt='icon.png' />
                                    </div>

                                </div>
                            </div>
                            <div className='logos_items_text'>
                                <div className='name_logos'> <p >RIYO Logistics</p></div>
                                <div className='name_logos'> <p >RIYO Business</p></div>
                                <div className='name_logos'> <p >RIYO Surakhsha</p></div>
                                <div className='name_logos'> <p >RIYO Parivaar</p></div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeOurservice
