import React from 'react'
import '../../Style/Home/HomeForm.css'
// import { Bounce } from 'react-awesome-reveal'

function HomeForm() {
    return (
        <>
            <div className="HomeForm">
                <div className='homeform_text'>
                    <h1>See What’s Happening at RIYO!!</h1>
                    <div className='homeform_line'></div>
                </div>

                <div className='homeform_fildes_container'>
                    <div className='homeform_box1'>
                        <span>Subscribe</span>
                        <p>To our newsletter</p>
                    </div>

                    <div className='homeform_box1_input'>

                        <p className='p-none-mobile-view'>Name  <span>*</span></p>
                        <div className='homeform_input_box'>
                            <input type="text" placeholder="Enter Your Name" />
                        </div>
                    </div>
                    <div className='homeform_box1_input'>

                        <p className='p-none-mobile-view'>Email  <span>*</span></p>
                        <div className='homeform_input_box'>
                            <input type="Email*" placeholder="Enter Your Email Address" />
                        </div>
                    </div>
                    <div className='homeform_box1_input none_mobile_view'>

                        <p>Phone  <span>*</span></p>
                        <div className='homeform_input_box'>
                            <input type="text" placeholder="Enter Your Phone Number" />
                        </div>
                    </div>

                   <div className='btn_box'>
                       <button className="homeform_btn">Subscribe</button>
                   </div>
                    
                </div>
            </div>
        </>
    )
}

export default HomeForm
