import React from 'react'
import '../../Style/RiyoLogistics/RiyoLogisticsGetInTouch.css'
import { Bounce } from 'react-awesome-reveal'
import { LuPhone } from "react-icons/lu";
import { CiMail } from "react-icons/ci";
import Logo from '../../Asset/RiyoLogistics/GetintouchLogo.svg'

function RiyoLogisticsGetInTouch({logisticAllData}) {
  return (
    <>
      <div className="RiyoLogisticsGetInTouch">
     
        <div className="RiyoLogisticsGetInTouch-heading">
            <h1><Bounce>{logisticAllData?.contact.heading}</Bounce></h1>
        </div>
        <div className="RiyoLogisticsGetInTouch-details">
            <div className='RiyoLogisticsGetInTouch-details-flex-box'>
            <a href="tel:03335561099" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', gap: '1rem' }}>
           <div className='RiyoLogisticsGetInTouch-icon'>
           <LuPhone size={20} style={{color:"var(--clr-white)"}}/>
           </div>
            <p>{logisticAllData?.contact.mobilenumber}</p>
            </a>
            </div>
            <br className='display-none' />
            <br className='display-none'/>
            <br />
            <div className='RiyoLogisticsGetInTouch-details-flex-box'>
            <a href="mailto:Hello@riyorentitoverindia.com" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <div className='RiyoLogisticsGetInTouch-icon'>
            <CiMail size={20}/>
            </div>
            <p>{logisticAllData?.contact.email}</p>
            </a>
            </div>
        </div>
        <div className="RiyoLogisticsGetInTouch-background-logo">
        <img src={Logo} alt="" />
        </div>
        
      </div>
    </>
  )
}

export default RiyoLogisticsGetInTouch
