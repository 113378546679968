import React from 'react'
import '../../Style/RiyoServices/RiyoSurakhshaSection.css'
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import { Link } from 'react-router-dom';

function RiyoSurakhshaSection() {
    const dispatch = useDispatch()
    const handale = (value) => {
        dispatch(setCurrentPage(value))
    }

    return (
        <>
            <div className='riyo-surakhsha-section'>
                <div className='riyosurakhsha-div'>
                        <div className='riyo-surakhsha-box1'>
                            <div className='riyo-surakhsha-imgbox'>
                                <div className='riyo-surakhsha-imgbox1'>
                                </div>
                            </div>
                        </div>

                    <div className='riyo-surakhsha-box11'>
                    </div>
                    <div className='riyo-surakhsha-box2'>
                        <div className='riyo-surakhsha-para'>
                            <div className='circle-text'>

                                <h1 className='riyo-surakhsha-text'>RIYO Surakhsha</h1>
                            </div>
                            <div className='riyo-surakhsha-para2'>
                                <p><span className='span-at'>At</span> <span className='span-riyo'>RIYO SURAKHSHA,</span> We empowering commercial truck owners across India, RIYO Rentitover presents a groundbreaking solution tailored exclusively for them. In a market dominated by individual truck proprietors, who serve as the lifeline of various sectors including manufacturing, mining, and agriculture, RIYO acknowledges the challenges they face daily. From overseeing truck operations to managing driver issues and grappling with RTO-related hurdles, truck owners endure significant mental and financial strain.</p>
                                <Link to="/RiyoLogistics">
                                    <button onClick={() => handale("RiyoShurakhshaData")}>Know more</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default RiyoSurakhshaSection