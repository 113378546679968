import React from 'react'
import '../../Style/ContactUs/HeroHomeContact.css'
import { AiOutlineInfoCircle } from "react-icons/ai";


function HeroHomeContact() {
  return (
    <>
      <div className='contact-us-div1'>
        <div className='contact-us-div2'>

          <div className='container-1'>
            <div className='conatiner-text'>
              <div className='container-box'>
                <p>For more queries</p>
                <h1>Get In Touch</h1>
                <div className='input-box'>
                  <div className='input-1'>
                    <input type='text' placeholder='Enter Your  First Name' className='input-color' />
                    <input type='text' placeholder='Enter Your  Last Name' />
                  </div>

                  <div className='input-1'>
                    <input type='text' placeholder='Enter Your Phone Number' />
                    <input type='text' placeholder='Enter Your Email Address' />
                  </div>
                  <div className='text-area-conatiner'>
                    <textarea placeholder='Enter Message' />


                  </div>
                  <div className='button-container'>
                    <button><b>Submit</b></button>

                  </div>


                </div>

              </div>


            </div>

            <div className='container-heading'>
              <div className='design-box1'>
                <div className='curve-1'>

                </div>
                <div className='curve-2'>

                </div>

              </div>
              <div className='contact-info-box'>
                <h2 style={{ color: "white", marginLeft: "37%", marginTop: "-2%", padding: "5% 0%" }} >Contact Info</h2>
                <div className='contact-detalis'>
                  <div className='first-logo'>
                    <div className='icon-div'>
                      <AiOutlineInfoCircle className='icon11' />
                    </div>
                    <div className='text-div2'>
                      <p className='font-weight'>RIYO Office</p>
                      <p>2nd Floor SK Tower, Behind Reliance Market, Niranjanpur, Mandi, Dehradun, UK, 248001, India</p>

                    </div>
                  </div>


                  <a href="tel:03335561099" style={{ textDecoration: 'none', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <div className='first-logo'>
                      <div className='icon-div'>
                        <AiOutlineInfoCircle className='icon11' />

                      </div>
                      <div className='text-div2'>
                        <p className='font-weight'>Phone Number</p>
                        <p>033 35561099</p>

                      </div>
                    </div>
                  </a>

                  <a href="mailto:Hello@riyorentitoverindia.com" style={{ textDecoration: 'none', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <div className='first-logo'>
                    <div className='icon-div'>
                      <AiOutlineInfoCircle className='icon11' />

                    </div>
                    <div className='text-div2'>
                      <p className='font-weight' >Mail Us</p>
                      <p>Hello@riyorentitoverindia.com</p>

                    </div>
                  </div>
                  </a>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default HeroHomeContact