import React from 'react'
import Logo from '../../image/Group 3426.png'
import grid from '../../image/grid.png'
import Truck from "../../image/truckimg.png"
import industry from "../../image/industry.png"

function TermconditionHeroSection() {
  return (
    <>
       <div className='terms-conditions'>
        <div className='terms-box'>
          <h1>Terms & Conditions</h1>
          <img src={grid} className='terms-grid' alt=''/>
          <div className='truck'>
          <img src={Truck} className='img22' alt='' />
            <img src={Truck} className='img22' alt='' />
            <img src={industry} className='img33' alt=''/>
          </div>
          <img src={Logo} alt="" height='100%' className='search-logo' />
        </div>
      </div>
    </>
  )
}

export default TermconditionHeroSection
