import React from 'react'
import '../../Style/RiyoServices/RiyoBusinessSection.css'
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import { Link } from 'react-router-dom';

function RiyoBusinessSection() {
    const dispatch = useDispatch()
    const handale = (value) => {
        dispatch(setCurrentPage(value))
    }
    return (
        <>
            <div className='riyo-business-section'>
                <div className='riyobusiness-div'>
                    <div className='riyo-business-box11'></div>
                    <div className='riyo-business-box2'>
                        <div className='riyo-business-para'>
                            <div className='businesscircle-text'>
                                <h1 className='riyo-business-text'>RIYO Business</h1>
                            </div>
                            <div className='riyo-business-para2'>
                                <p><span className='span-at'>At</span> <span className='span-riyo'>RIYO BUSINESS,</span> is an innovative mobile application designed to empower lorry brokers by providing them with a comprehensive platform to expand and enhance their business. With RIYO Business, lorry brokers gain access to a range of powerful tools and features tailored to their needs, enabling them to efficiently manage their operations, connect with clients, and secure profitable business opportunities. </p>
                                <Link to="/RiyoLogistics">
                                    <button onClick={() => handale("RiyoBusinessData")}>Know more</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='riyo-business-box1'>
                        <div className='riyo-business-imgbox'>
                            <div className='riyo-business-imgbox2'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RiyoBusinessSection