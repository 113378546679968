import React from 'react'
import '../../Style/AboutUsRiyoBusiness/MissionVision.css'
import Slider from "react-slick";
import img1 from "../../image/sliderimg1.png"
import img2 from "../../image/sliderimg2.png"
import img3 from "../../image/sliderimg3.png"
import img4 from "../../image/sliderimg4.png"




function MissionVision() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow:<></> ,
    prevArrow: <></>
  };
  
  return (
    <>
    <div className='missionVision-box'>
        <div className='missionVision-box2'>
          <div className='flip-box1'>
          <div className='mission-details-box'>
            <h5>Mission</h5>
            <p>RIYO is committed to transforming logistics through seamless tech integration. Our Riyo app connects industry facets, streamlining operations for stakeholders. Operating across logistics, we unify major players for efficient workload management. Our mission prioritizes industry enhancement, fostering collaboration in an interactive space. We empower stakeholders, driving optimization and innovation in logistics management.</p>
            </div>
          </div>
         <div className='slider-box'>
         <Slider {...settings}>
        <div>
          <img src={img1} className='slide-img'/>
        </div>
        <div>
          <img src={img2}className='slide-img'/>
        </div>
        <div>
        <img src={img3}className='slide-img'/>
        </div>
       {/* ggg */}
        
        <div>
        <img src={img4} className='slide-img'/>
        </div>
      </Slider>

         </div>
         <div className='flip-box-2'>
          <div className='mission-details-box2'>
            <h5>Vision</h5>
            <p>Our vision is to optimize India's highways by consolidating and refining critical services for national roadways. We strive to broaden and enhance our solutions across all Indian national highways, establishing ourselves as a premier provider of efficient logistics solutions to tackle industry challenges effectively.</p>

          </div>
           
         </div>
        </div>
    

    </div>
    </>
  )
}

// pull

export default MissionVision