import React from 'react'
import HeroHomeRiyoServices from '../components/RiyoServices/HeroHomeRiyoServices'
import RiyoLogSection from '../components/RiyoServices/RiyoLogSection'
import RiyoSurakhshaSection from '../components/RiyoServices/RiyoSurakhshaSection'
import RiyoParivaar from '../components/RiyoServices/RiyoParivaar'
import RiyoBusinessSection from '../components/RiyoServices/RiyoBusinessSection'
import HowToRegister from '../components/RiyoServices/HowToRegister'
import Download from '../components/RiyoServices/Download'
import RiyoServicesMobileApp from '../components/RiyoServices/RiyoServicesMobileApp'
import RiyoServicesApplication from '../components/RiyoServices/RiyoServicesApplication'
import Header from '../components/Layout/Header'

function RiyoServices() {
  return (
    <>
      <Header />
      <HeroHomeRiyoServices />
      <RiyoLogSection />
      <RiyoBusinessSection />
      <RiyoSurakhshaSection />
      <RiyoParivaar />
      <HowToRegister />
      <Download />
      <RiyoServicesMobileApp/>
      <RiyoServicesApplication/>

    </>
  )
}
export default RiyoServices