import './App.css';
import Footer from './components/Layout/Footer';
import TermsAndConditions from './pages/TermsAndConditions';
import Home from './pages/Home';
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import RiyoLogistics from './pages/RiyoLogistics';
import ContactUs from './pages/ContactUs';
import AboutUsRiyoBusiness from './pages/AboutUsRiyoBusiness';
import RiyoServices from './pages/RiyoServices';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import PrivcyPolicy from './pages/PrivcyPolicy';
// import TermsAndConditions from './pages/TermsAndConditions';


function App() {
  return (
    <>
   <BrowserRouter>
   <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/RiyoLogistics' element={<RiyoLogistics/>}></Route>
        <Route path='/TermsAndConditions' element={<TermsAndConditions/>}/>
        <Route path='/ContactUs' element={<ContactUs/>}/>
        <Route path='/AboutUsRiyoBusiness' element={<AboutUsRiyoBusiness/>}/>
        <Route path='/RiyoServices' element={<RiyoServices/>}/>
        <Route path='/PrivcyPolicy' element={<PrivcyPolicy/>}/>
      </Routes>
      <Footer/>
   </BrowserRouter>
    </>
  );
}
export default App;


