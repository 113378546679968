import React from 'react'
import HeroHome from '../components/Home/HeroHome'
import HomeSection2 from '../components/Home/HomeSection2'
import HomeOurservice from '../components/Home/HomeOurservice'
import HomeAouImpact from '../components/Home/HomeAouImpact'
import HomeOurPresence from '../components/Home/HomeOurPresence'
import HomeCompanyCorner from '../components/Home/HomeCompanyCorner'
import HomeAboutUs from '../components/Home/HomeAboutUs'
// import HomeOurMission from '../components/Home/HomeOurMission'
// import HomeRiyoNetwork from '../components/Home/HomeRiyoNetwork'
import HomeGetTouch from '../components/Home/HomeGetTouch'
import HomeForm from '../components/Home/HomeForm'
import Header from '../components/Layout/Header'
import HeroHomeNaBar from '../components/Home/HeroHomeNaBar'
import RiyoServicesMobileApp from '../components/RiyoServices/RiyoServicesMobileApp'
import RiyoServicesApplication from '../components/RiyoServices/RiyoServicesApplication'

function Home() {
  return (
    <div>
      <HeroHome/>
      <Header/>
      {/* <HeroHomeNaBar/> */}
      <HomeSection2/>
      <HomeOurservice/>
      <HomeAouImpact/>
      <HomeOurPresence/>
      <HomeCompanyCorner/>
      <HomeAboutUs/>
      <RiyoServicesMobileApp />
      <RiyoServicesApplication />
      {/* <HomeOurMission/> */}
      {/* <HomeRiyoNetwork/> */}
      <HomeGetTouch/>
      <HomeForm/>
    </div>
  )
}

export default Home
