import React from 'react'
import '../../Style/ContactUs/Map.css'

function Map() {
  return (
    <>
    <div className='map-container'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3444.593676304145!2d78.00584607609892!3d30.305626974793128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092b0dc5b66fdf%3A0xe66e55ad8e0609a8!2sWEBREATE!5e0!3m2!1sen!2sin!4v1714822755970!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </div>
    </>
  )
}

export default Map