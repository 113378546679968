import React from 'react';
import '../../Style/Home/HeroHome.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Logo from "../../Asset/Logo/whitelogo.png";
import { Link } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookCircleLine } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/Features/Currentpages';
import Icon1 from "../../Asset/Home/Vector (4).png"
import Icon2 from "../../Asset/Home/Vector (5).png"
import Icon3 from "../../Asset/Home/Victor (9).png"
import Icon4 from "../../Asset/Home/Vector (6).png"
import Icon5 from "../../Asset/Home/Vector (8).png"
import Icon6 from "../../Asset/Home/victor (7).png"

function HeroHome() {
    const dispatch = useDispatch()
    const redirectpage = (value) => {
        dispatch(setCurrentPage(value))
        // alert(value)
    }
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        nextArrow: <></>,
        prevArrow: <></>,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover:true

    };

    const redirectToWhatsApp = () => {
        window.location.href = `https://wa.me/+916289688570`; 
      };

    return (
        <>
            <div className="HeroHomeMain">
                <div className="HeroHome-sideBar">
                    <div className='HeroHome-sideBar-tetails'>
                        <div className='images_container'>
                            <div className='image-wripper'>
                                <img src={Logo} height="" alt='' />
                            </div>
                        </div>
                        <ul>
                            <li><span><Link to='/'>Home</Link></span></li>
                            <li><span><Link to='/AboutUsRiyoBusiness'>About Us</Link></span></li>
                            <li><span><Link to="/RiyoServices">Riyo Services</Link></span></li>
                            <li><span>Gallery</span></li>
                            <li><span>Blog</span></li>
                            <li><span><Link to="/ContactUs">Contact Us</Link></span></li>
                        </ul>
                    </div>
                    <div className='HeroHome-sideBar-logo'>
                        <div className="HeroHome-Sidebar-position">
                            <div className='log_in'><a href="https://riyorentitoverindia.in/">Log in</a></div>
                            <div className='HeroHome-sideBar-logo-image'>
                                <div className='icon'><a href="#" onClick={redirectToWhatsApp}><FaWhatsapp size={20} /></a></div>
                                <div className='icon'><a href="https://www.facebook.com/share/8vVBgoU3yA1YYoZX/?mibextid=qi2Omg"><RiFacebookCircleLine size={20} /></a></div>
                                <div className='icon'><a href="https://www.instagram.com/riyoindia_?igsh=MXQ4MHc0eGV4OWt6NA=="><FaInstagram size={20} /></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="herosidebarallgrids">
                        <div className="sidebar-y-all-line">
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                            <div className="sidebar-y-line"></div>
                        </div>
                        <div className="sidebar-x-all-line">
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                            <div className="sidebar-x-line"></div>
                        </div>
                    </div>
                </div>

                <div className='Mobile_show_container'>
                    <div className='Mobile_show_container_inside'>
                        <div className='image-wripper_mobile'>
                            <img src={Logo} height="100px" alt='' />
                        </div>

                        <div className='mobile_view_icons_container'>
                            <Link to='/'><img src={Icon1} alt='logo' /></Link>
                            <Link to='/AboutUsRiyoBusiness'><img src={Icon2} alt='logo' /></Link>
                            <Link to='/RiyoServices'><img src={Icon3} alt='logo' /></Link>
                            <img src={Icon4} alt='logo' />
                            <img src={Icon5} alt='logo' />
                            <Link to='/ContactUs'><img src={Icon6} alt='logo' /></Link>
                        </div>
                        <div className='HeroHome-sideBar-logo-image_mobile_view'>
                            <div className='icon'><a href="#" onClick={redirectToWhatsApp}  style={{ color: '#25D366', textDecoration: 'none'}}><FaWhatsapp size={20} /></a></div>
                            <div className='icon'><a href="https://www.facebook.com/share/8vVBgoU3yA1YYoZX/?mibextid=qi2Omg" style={{ color: '#4267B2', textDecoration: 'none'}}><RiFacebookCircleLine size={20} /></a></div>
                            <div className='icon'><a href="https://www.instagram.com/riyoindia_?igsh=MXQ4MHc0eGV4OWt6NA=="  style={{ color: '#C13584', textDecoration: 'none',}}><FaInstagram size={20} /></a></div>
                        </div>
                    </div>
                </div>

                <div className="HeroHome-position">
                    <Slider {...settings}>
                        <div>
                            <div className="HeroHome">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className='We_container_we'>
                                                <p>We are RIYO </p>
                                                <div className='hr_we'></div>
                                            </div>
                                            <h1>INDIA's first leading one stop Tech solution to revolutionizes logistics and transportation sector.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="HeroHome1">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        {/* only show mobile view */}
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className="What_we_box">
                                                <p>What we do for?</p>
                                                <div className='what_we_do_line'></div>
                                            </div>
                                            <div className='company_name_conatiner'>
                                                <h1>Industries</h1>
                                            </div>
                                            <p className='p1'>We provide industry owners with a comprehensive suite of services, including load posting, verified transporters, lorry owners, drivers, and brokers. We prioritize material delivery with top-notch safety measures, ensuring client peace of mind. With timely updates throughout the transportation process, we assure transparency and reliability, empowering businesses to excel in today's competitive marketplace. Optimize your logistics strategy with our tailored solutions....</p>
                                            <div className='btn-box'>
                                                <Link to='/RiyoLogistics'><button onClick={() => redirectpage('riyoLogisticData')} className='btn-box-btn'>Know More</button></Link>
                                                <a href='https://play.google.com/store/apps/details?id=com.riyo.riyo_logistics'>Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="HeroHome5">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        {/* only show mobile view */}
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className="What_we_box">
                                                <p>What we do for?</p>
                                                <div className='what_we_do_line'></div>
                                            </div>
                                            <div className='company_name_conatiner'>
                                                <h1>Transport Company</h1>
                                            </div>
                                            <p className='p1'>We specialize in providing crucial support to transport companies, offering verified industries, lorry owners, drivers, and brokers. Our primary focus is on ensuring the safe and secure delivery of materials, backed by stringent safety protocols. We guarantee transparency and reliability with timely updates throughout the transportation process. Additionally, our services include facilitating load posting on behalf of industries, streamlining logistics, and maximizing efficiency. Partner with us for a smooth and efficient transportation experience.....</p>
                                            <div className='btn-box'>
                                                <Link to='/RiyoLogistics'><button onClick={() => redirectpage('riyoLogisticData')} className='btn-box-btn'>Know More</button></Link>
                                                <a href='https://play.google.com/store/apps/details?id=com.riyo.riyo_logistics'>Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="HeroHome4">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        {/* only show mobile view */}
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className="What_we_box">
                                                <p>What we do for?</p>
                                                <div className='what_we_do_line'></div>
                                            </div>
                                            <div className='company_name_conatiner'>
                                                <h1>Lorry Broker</h1>
                                            </div>
                                            <p className='p1'>We are innovative mobile application designed to empower lorry brokers by providing them with a comprehensive platform to expand and enhance their business. With RIYO Business, lorry brokers gain access to a range of powerful tools and features tailored to their needs, enabling them to efficiently manage their operations, connect with clients, and secure profitable business opportunities.... </p>
                                            <div className='btn-box'>
                                                <Link to='/RiyoLogistics'><button onClick={() => redirectpage('RiyoBusinessData')} className='btn-box-btn'>Know More</button></Link>
                                                <a href='https://play.google.com/store/apps/details?id=com.riyo.riyo_logistics'>Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="HeroHome2">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        {/* only show mobile view */}
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className="What_we_box">
                                                <p>What we do for?</p>
                                                <div className='what_we_do_line'></div>
                                            </div>
                                            <div className='company_name_conatiner'>
                                                <h1>Lorry Owner</h1>
                                            </div>
                                            <p className='p1'>We empowering commercial truck owners across India, RIYO Rentitover presents a groundbreaking solution tailored exclusively for them. In a market dominated by individual truck proprietors, who serve as the lifeline of various sectors including manufacturing, mining, and agriculture, RIYO acknowledges the challenges they face daily...</p>
                                            <div className='btn-box'>
                                                <Link to='/RiyoLogistics'><button onClick={() => redirectpage('RiyoShurakhshaData')} className='btn-box-btn'>Know More</button></Link>
                                                <a href='https://play.google.com/store/apps/details?id=com.riyo.riyo_logistics'>Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="HeroHome3">
                                <div className='HeroHome-Opecity'>
                                    <div className="HeroHome-detail">
                                        {/* only show mobile view */}
                                        <div className='m-responsive-login'>
                                            <button >Login</button>
                                        </div>
                                        {/*  */}
                                        <div className='HeroHome-detail_details'>
                                            <div className="What_we_box">
                                                <p>What we do for?</p>
                                                <div className='what_we_do_line'></div>
                                            </div>
                                            <div className='company_name_conatiner'>
                                                <h1>Lorry Driver</h1>
                                            </div>
                                            <p className='p1'>We unlock a smoother journey for commercial truck drivers navigating the diverse landscape of national highways. RIYO Rent It Over India Pvt Ltd introduces the RIYO Family policy, aimed at revolutionizing driving conditions for truckers. With a focus on addressing language barriers, cultural differences, and essential needs, RIYO's innovative mobile application streamlines access to vital services right on the highway....</p>
                                            <div className='btn-box'>
                                                <Link to='/RiyoLogistics'><button onClick={() => redirectpage('RiyoParivaarData')} className='btn-box-btn'>Know More</button></Link>
                                                <a href='https://play.google.com/store/apps/details?id=com.riyo.riyo_logistics'>Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default HeroHome;
