import React from 'react'
import Header from '../components/Layout/Header'
import '../Style/Term&Conditions/Term&conditionHeroSection.css'
import Termandconditions from '../components/Term&Conditions/TermAndConditionsSection'
import TermconditionHeroSection from '../components/Term&Conditions/Term&conditionHeroSection'



function TermsAndConditions() {
  return (
    <>
      <Header />
      <TermconditionHeroSection/>
      <Termandconditions/>
    </>
  )
}

export default TermsAndConditions