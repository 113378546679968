import React from 'react'
import HeroHomeContact from '../components/Contact Us/HeroHomeContact'
import Map from '../components/Contact Us/Map'
import Header from '../components/Layout/Header'
function ContactUs() {
  return (
    <>
      <Header />
      <HeroHomeContact />
      <Map />
    </>
  )
}

export default ContactUs